<!--
 * @Author: DY
 * @Date: 2022-11-11 10:21:23
 * @LastEditTime: 2022-11-11 13:20:31
 * @Description:
 * @FilePath: \BusinessPlatform\src\views\login\components\loginModule.vue
-->
<template>
    <div class="loginModule">
        <div class="loginBar">
            <el-tabs v-model="activeName">
                <el-tab-pane label="密码登录" name="password" class="passwordLogin">
                    <ul>
                        <li>
                            <el-input v-model="accountNumber" placeholder="请输入账号" clearable>
                                <i slot="prefix" class="iconfont iconshouji"></i>
                            </el-input>
                        </li>
                        <li>
                            <el-input placeholder="请输入密码" v-model="password" show-password>
                                <i slot="prefix" class="iconfont iconsuo1"></i>
                            </el-input>
                        </li>
                        <li class="verificationCode">
                            <el-input v-model="verification" placeholder="请输入验证码">
                                <i slot="prefix" class="iconfont iconyanzhengma"></i>
                            </el-input>
                            <div class="verificationCodeBox">
                                <img :src="verificationImg" alt="验证码" @click="getValiCode">
                            </div>
                        </li>
                        <li class="passwordOperation">
                            <el-checkbox v-model="checked">
                                记住密码
                            </el-checkbox>
                            <button class="forgetPassword" @click="resetPassword">
                                忘记密码?
                            </button>
                        </li>
                    </ul>
                </el-tab-pane>
                <el-tab-pane label="手机验证码登录" name="verificationCode" class="verificationCodeLogin">
                    <el-form
                        ref="loginForm"
                        class="form-inline"
                        :rules="rules"
                        :inline="true"
                        :model="formInline"
                    >
                        <ul>
                            <li class="phoneNumber">
                                <el-form-item prop="phoneNumber">
                                    <el-input v-model="formInline.phoneNumber" placeholder="请输入账号" clearable>
                                        <i slot="prefix" class="iconfont iconshouji"></i>
                                    </el-input>
                                </el-form-item>
                            </li>
                            <li class="verificationCode">
                                <el-input v-model="formInline.verification" placeholder="请输入短信验证码" :disabled="formInline.phoneNumber == ''">
                                    <i slot="prefix" class="iconfont iconyanzhengma"></i>
                                </el-input>
                                <el-button
                                    type="primary"
                                    :disabled="disable"
                                    :class="{ codeGeting: isGeting }"
                                    @click="getVerificationCode"
                                >
                                    {{ btnNmae }}
                                </el-button>
                            </li>
                        </ul>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <div class="loginBtn">
                <el-button type="primary" @click="submit" :loading="submitLoading">
                    立 即 登 录
                </el-button>
            </div>
            <div class="termAgreement">
                登录即视为同意<span>《服务协议》</span>及<span>《隐私政策》</span>
            </div>
        </div>
    </div>
</template>

<script>
import { reAddRouters } from '@/utils';
import currencyFun from '@/assets/js/currencyFun';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import cookie from '@/common/NIM/utils/cookie';


export default {
    name: 'login-module',
    components: {},
    data() {
        return {
            guid: '',
            activeName: 'password',
            // 点击登录按钮loading
            submitLoading: false,
            accountNumber: '',
            password: '',
            verification: '',
            verificationImg: '',
            checked: null,

            formInline: {
                phoneNumber: '',
                verification: '',
            },
            rules: {
                phoneNumber: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur',
                }, {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '手机号格式错误',
                    trigger: 'blur',
                }],
            },

            // 验证码登录-获取验证码按钮状态
            btnNmae: '获取验证码',
            isGeting: false,
            count: 60,
            disable: false,
        };
    },
    watch: {},
    created() {
        const _that = this;
        document.onkeydown = function (e) {
            e = window.event || e;
            if (_that.$route.path === '/login' && ['Enter', 'enter', 'NumpadEnter'].indexOf(e.code) > -1) {
                _that.submit();
            }
        };
    },
    mounted() {
        this.guid = this.newGuid();
        this.getValiCode();
        this.getCookies();
    },
    computed: {},
    methods: {
        newGuid() {
            return (
                `${this.s4()}${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`
            );
        },

        s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        },

        // 获取Cookies保存账号
        getCookies() {
            this.accountNumber = Cookies.get('userName') ? Cookies.get('userName') : '';
            const enPassword = Cookies.get('userPwd') ? Cookies.get('userPwd') : '';
            if (enPassword) {
                this.password = CryptoJS.AES.decrypt(enPassword, 'user').toString(CryptoJS.enc.Utf8);
                this.checked = true;
            } else {
                this.password = '';
            }
        },

        // 记住密码-保存账号到Cookies
        setCookiesUser() {
            if (this.checked) {
                Cookies.set('userName', this.accountNumber, {
                    expires: 3,
                });
                Cookies.set('userPwd', CryptoJS.AES.encrypt(this.password, 'user'), {
                    expires: 3,
                });
            } else {
                Cookies.remove('userName');
                Cookies.remove('userPwd');
            }
        },

        // 验证码
        getValiCode() {
            this.verificationImg = `/interfaceApi/auth/ValidateCode?guid=${this.guid}&width=115&height=54&s=${Math.random()}&fontSize=30`;
        },

        // 手机登录获取验证码
        async getVerificationCode() {
            this.$refs.loginForm.validate(async valid => {
                if (valid) {
                    const sent = await this.getVerificationCodeRequrst(this.formInline.phoneNumber);
                    if (sent) {
                        const countDown = setInterval(() => {
                            if (this.count < 1) {
                                this.isGeting = false;
                                this.disable = false;
                                this.btnNmae = '获取验证码';
                                this.count = 60;
                                clearInterval(countDown);
                            } else {
                                this.isGeting = true;
                                this.disable = true;
                                this.btnNmae = this.count-- + 's后重新发送';
                            }
                        }, 1000);
                    }
                }
            });
        },

        // 登录
        submit() {
            const qs = require('qs');
            if (this.activeName === 'password') {
                if (this.accountNumber === '') {
                    this.$message.error('请输入用户账号');
                } else if (this.password === '') {
                    this.$message.error('请输入密码');
                } else if (this.verification === '') {
                    this.$message.error('请输入验证码');
                } else {
                    this.submitLoading = true;
                    this.$axios
                        .post('/interfaceApi/login/business', qs.stringify({
                            ImgGuid: this.guid,
                            password: this.password,
                            ImgCode: this.verification,
                            username: this.accountNumber,
                        }), {
                            headers: {
                                ContentType: 'application/x-www-form-urlencoded',
                            },
                        }).then(async res => {
                            this.setCookiesUser();
                            this.loginSucceeded(res);
                        }).catch(res => {
                            if (res.error === 'GO_2002') {
                                this.getValiCode();
                            }
                            this.$message.error(res.error_description);
                        }).finally(() => {
                            this.submitLoading = false;
                        });
                }
            } else if (this.activeName === 'verificationCode') {
                if (this.formInline.phoneNumber === '') {
                    this.$message.error('请输入手机号码');
                } else if (this.formInline.verification === '') {
                    this.$message.error('请先输入验证码');
                } else {
                    this.submitLoading = true;
                    this.$axios
                        .post('/interfaceApi/login/business/verifycode', qs.stringify({
                            phone_number: this.formInline.phoneNumber,
                            verify_code: this.formInline.verification,
                        }), {
                            headers: {
                                ContentType: 'application/x-www-form-urlencoded',
                            },
                        }).then(async res => {
                            const numberVerifications = await this.getNumberVerifications(this.formInline.phoneNumber);
                            if (numberVerifications) {
                                this.loginSucceeded(res);
                            } else {
                                this.pictureVerification(res);
                            }
                        }).catch(() => {
                            this.$message({
                                showClose: true,
                                message: '登录失败!',
                                type: 'error',
                            });
                        }).finally(() => {
                            this.submitLoading = false;
                        });
                }
            }
        },

        // 短信验证成功-图片验证
        pictureVerification(res) {
            this.$emit('callPictureVerification', res);
        },

        // 登录成功保存用户信息
        async loginSucceeded(res) {
            sessionStorage.setItem('token', `${res.token_type} ${res.access_token}`);
            sessionStorage.setItem('refreshToken', `${res.token_type} ${res.refresh_token}`);
            sessionStorage.setItem('clientID', this.guid);
            // 保存用户信息
            await currencyFun.getUserInfo();
            const name = this.$takeTokenParameters('Name');
            this.getNIMAccount(name, res);
        },

        // 获取云信账号
        getNIMAccount(name, user) {
            this.$axios
                .get('/interfaceApi/message/MessageNetease/GetRelInfo?userName=' + name)
                .then(() => {
                    // cookie.setCookie('uid', res.UserId);
                    // cookie.setCookie('sdktoken', res.Token);
                    // this.$store.dispatch('connect', this.$store);
                    this.loginWebIM(user);
                    cookie.setCookie('isNIM', 'true');
                    // console.log(res, 'resssss');
                    this.loginJump();
                }).catch(error => {
                    // cookie.delCookie('uid');
                    // cookie.delCookie('sdktoken');
                    console.log(222);
                    cookie.setCookie('isNIM', 'false');
                    this.$message.error(error.ErrorCode.Message);
                    this.loginJump();
                });
        },
        // 登录跳转
        loginJump() {
            localStorage.setItem('menuCode', 'shebc');
            // 重新添加路由信息
            reAddRouters();
            this.$router.push({ path: '/' });
        },

        // 重置密码
        resetPassword() {
            this.$emit('resetPasswordLoadPage');
        },

        // 获取短信验证码请求
        getVerificationCodeRequrst(phoneNumber) {
            return this.$axios.$get(`/interfaceApi/auth/verifycode/${phoneNumber}/1`, { defEx: true });
        },

        // 获取短信验证次数请求
        getNumberVerifications(phoneNumber) {
            return this.$axios.$get(`/interfaceApi/auth/errorcheck/${phoneNumber}`, { defEx: true });
        },
        // 登录账号
        async loginWebIM(user) {
            this.$imoption.user = user.AccId;
            this.$imoption.pwd = user.Token;
            localStorage.setItem('userDataDetail', JSON.stringify(user));

            console.log(this.$imoption, 'login');

            this.$imconn.open(this.$imoption);
            const _this = this;
            // 监听回调
            this.$imconn.listen({
                onOpened: function (message) {
                    console.log(message, '用户已上线');
                },
                onClosed: function (message) {
                    console.log(message, '用户下线');
                    _this.$emit('isclose', true);
                },
                // 收到文本消息
                onTextMessage: function (message) {
                    console.log(message, 'messagemessage');
                    if (message.from === _this.accept) {
                        _this.chatContent.push({
                            replyImg: '',
                            replyContent: message.data,
                        });
                        // _this.keepbottom();
                    }
                },
            });
        },
        getUser() {
            return this.$axios.get('/interfaceApi/auth/connect/userinfo');
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.loginModule
    height 100%
    background url('./../images/bg_info.png') left no-repeat, url('./../images/bg_yw.png') center no-repeat
    background-size 7.1rem 5.3rem,100%
    background-position 10% center, center
    position: relative
    .loginBar
        width 25%
        height: 4.62rem
        position: absolute
        right 1.1rem
        top 50%
        transform: translateY(-50%)
        background: #fff
        .underDevelopment
            font-size: fs_50
            color: color_5
            text-align: center
            line-height: 1rem
        >>>.el-tabs
            width 100%
            .el-tabs__header
                margin-bottom: 0
                .el-tabs__nav
                    width 100%
                    .el-tabs__item
                        width 50%
                        height: 0.52rem
                        line-height: 0.52rem
                        font-size fs_18
                        color color_3
                        text-align: center
                        padding 0
                        &.is-active
                            background: rgba(0, 120, 251, 0.06)
            .el-tabs__content
                height 3rem
                .passwordLogin
                    padding: 0.4rem 0.3rem
                .verificationCodeLogin
                    padding: 0.6rem 0.3rem 0.4rem
                    li
                        .el-form-item
                            width 100%
                            margin 0
                            .el-form-item__content
                                width 100%
                    .verificationCode
                        margin-bottom: 1.16rem
                        .el-input
                            width calc(100% - 1.5rem)
                        .el-button
                            width 1.4rem
                            margin-left: 0.1rem
                            font-size 0.14rem
                ul
                    li
                        margin-bottom: 0.24rem
                        &:last-child
                            margin-bottom: 0
                        .el-input
                            .el-input__inner
                                border-radius: 2px
                                height: 0.44rem
                                line-height: 0.44rem
                            .el-input__prefix
                                line-height: 0.44rem
                                .iconfont
                                    font-size fs_18
                            .el-input__suffix
                                .el-input__suffix-inner
                                    line-height 0.44rem
                        &.verificationCode
                            display: flex
                            .verificationCodeBox
                                width 1.2rem
                                height: 0.44rem
                                margin-left: 0.1rem
                                overflow hidden
                                border 1px solid color_5
                                img
                                    width 100%
                        &.passwordOperation
                            display: flex
                            justify-content space-between
                            .forgetPassword
                                border none
                                background: none
                                color color_6
                                cursor pointer
        .loginBtn
            padding 0 0.3rem
            margin-bottom: 0.24rem
            .el-button
                width 100%
                padding: 0
                height 0.44rem
                line-height: 0.44rem
                font-size fs_16
                &.codeGeting
                    background: #cdcdcd;
                    border-color: #cdcdcd;
        .termAgreement
            color color_3
            font-size: fs_12
            text-align: center
            span
                color color_30
                cursor pointer
</style>
