/*
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 * 
 * 
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * 
 *            佛祖保佑       永不宕机     永无BUG
 * 
 * @Author: DY
 * @Date: 2021-07-22 10:59:31
 * @LastEditTime: 2022-06-28 11:54:20
 * @Description: 通用脚本方法
 * @Param: 
 * @FilePath: \BusinessPlatform\src\assets\js\currencyFun\index.js
 */
import axios from 'axios';
const currencyFun = Object.create(null);

/**
 * @Description             按住户获取时间节点
 * @param time_type_key     时间类型
 * @param _this             请求对象
 * @returns {*}             时间节点 HH:mm:ss
 */
currencyFun.getTimeNode = async (time_type_key, _this) => {
    return await _this.$axios
        .get(`/interfaceApi/baseinfo/features_manage/info/${time_type_key}`)
        .then(res => {
            let defaultTime = {};
            if (res && res.feature_value) {
                defaultTime = JSON.parse(res.feature_value);
            }
            return defaultTime;
        })
        .catch(error => {
            _this.$message.error(error.ErrorCode.Message);
        });
};


/**
 * @Description             保存用户信息到缓存
 */
currencyFun.getUserInfo = async () => {
    return await axios
        .get('/interfaceApi/auth/connect/userinfo')
        .then(res => {
            const newInfo = encodeURI(JSON.stringify(res));
            sessionStorage.setItem('userInfo', newInfo);
        })
        .catch(error => {
            console.log(error.ErrorCode.Message);
        });
};

/**
 * 格式化时间
 * @param date  日期对象
 * @param fmt   格式化格式
 * @returns {*} 时间字符串 yyyy-MM-dd HH:mm:ss
 */
currencyFun.timeFormat = ({ date, fmt }) => {
    if (!date) {
        date = new Date();
    }
    const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));}
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
    }
    return fmt;
};


export default currencyFun;
